// @ts-nocheck
'use client';
import Image from 'next/image';
import AppleLogo from '../../../../assets/images/apple_logo.svg';
import huawei_logo from '../../../../assets/images/huawei_logo.svg';
import GoogleIcon from '../../../../assets/images/GoogleIcon.svg';
import FindAStore from '../../../../assets/images/FindAStore.svg';
import { Input, Button } from '@/components/shared/material';
import { useCallback, useState, useEffect } from 'react';
import { submitForm } from '@/actions';
import { Form } from '@/types/types';
import Link from 'next/link';
import { getFullUrl } from '@/lib/pathUtils';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { InputField } from '@/components/shared/form';
import InputToolTip from '@/components/shared/form/InputToolTip';
import NewsletterEmailField from '@/components/shared/form/EmailField';
import { getForm } from '@/lib/hubspot/services/formService';
interface FieldValidation {
  blockedEmailDomains: string[];
  useDefaultBlockList: boolean;
}

interface Field {
  objectTypeId: string;
  name: string;
  label: string;
  required: boolean;
  hidden: boolean;
  fieldType: string;
  validation: FieldValidation;
}

interface FieldGroup {
  groupType: string;
  richTextType: string;
}

// Example interface using the provided data

export default function MarketingCards(props: FieldGroup) {
  const [loading, setLoading] = useState(false);
  const [formResponse, setFormResponse] = useState<{
    inlineMessage?: string;
    redirect?: string;
  } | null>(null);

  const [form, setForm] = useState<Form | null>(null);
  const [fields = [], setFields] = useState<Field[] | null>(null);
  useEffect(() => {
    if (props.formId) {
      getForm(props.formId).then((form) => {
        setForm(form);
        setFields(form);
      });
    }
  }, [props.formId]);

  const formTitle = form?.name;
  const cardTitle = 'Footer';

  const handleSubmit = useCallback(
    async (values: any, { setSubmitting, resetForm }: any) => {
      setSubmitting(true);
      try {
        const pageURL = window.location.origin + window.location.pathname;
        const pageTitle = document.title;
        const response = await submitForm(form, values, pageURL, pageTitle);

        // If redirectUri is returned, redirect to that page
        if ('redirectUri' in response) {
          toast.success('Thank you. Redirecting you.', {
            position: 'bottom-center',
            icon: (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-4 w-4 animate-spin'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                />
              </svg>
            ),
            duration: 4000,
          });

          // Redirect user after 500 millsenconds
          setTimeout(() => {
            window.location.href = response.redirectUri;
          }, 500);
          resetForm();
        }

        if ('inlineMessage' in response) {
          toast.success(response.inlineMessage, {
            position: 'bottom-center',
            duration: 4000,
          });
          resetForm();
        }

        if (response.status === 'error') {
          console.log('error', response);
          toast.error(
            'There was a problem when trying to submit the form. Please try again, or alternatively contact us directly.',
            {
              position: 'bottom-center',
              duration: 6000,
            }
          );
          setSubmitting(false);
        }

        // Send Mixpanel form complete event
        mixpanelFormSubmission(
          form.displayOptions.submitButtonText ?? 'submit',
          formTitle ?? form.name,
          cardTitle ?? 'No card title'
        );
      } catch (error) {
        console.log('error', error);
      } finally {
      }
    },
    [form, formTitle, cardTitle]
  );

  return (
    <div className='relative z-10 -mt-8 mb-10 flex flex-row flex-wrap justify-between gap-6 md:flex-nowrap md:gap-4 xl:gap-2'>
      <div className='flex w-full flex-col flex-wrap items-start rounded-[10px] bg-charcoal-900 px-6 py-6 md:w-[28%] xl:w-[30%]'>
        <div>
          <p className='text-base text-yellow-50 md:text-sm/[12px] md:leading-6'>
            Download our award winning app from your app store
          </p>
        </div>
        <div className='mt-4 flex grid-cols-2 flex-wrap justify-between gap-4 md:justify-start'>
          <Link
            href='https://play.google.com/store/apps/details?id=ik.emerge.ikhokha'
            className='flex max-w-[120px] select-none rounded-md hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
          >
            <Image
              src={GoogleIcon}
              width={120}
              height={30}
              className='w-full select-none'
              alt='Google app logo'
            />
          </Link>
          <Link
            href={'https://appgallery.huawei.com/#/app/C102486477'}
            className='flex max-w-[120px] select-none rounded-md hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
          >
            <Image
              src={huawei_logo}
              width={120}
              height={30}
              className='w-full select-none'
              alt='huawei logo'
            />
          </Link>
          <Link
            href={'https://apps.apple.com/za/app/ikhokha/id1325073911'}
            className='flex w-full max-w-[120px] select-none rounded-md hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
          >
            <Image
              src={AppleLogo}
              width={110}
              height={30}
              className='w-full select-none'
              alt='Apple app logo'
            />
          </Link>
        </div>
      </div>

      <Link
        href={getFullUrl('store-locator')}
        className='flex w-full flex-wrap items-center gap-4 rounded-[10px] bg-charcoal-900 px-6 py-6 hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900 md:w-[20%] md:gap-2 xl:w-[18%] xl:flex-nowrap xl:gap-4'
      >
        <div>
          <div>
            <Image src={FindAStore} width={30} height={30} alt='Find a Store' />
          </div>
        </div>
        <div className='text-base text-yellow-50 md:text-sm/[12px] md:leading-6'>
          <p>Store Locator</p>
          <p>Find a store near you</p>
        </div>
      </Link>

      <div className='flex w-full flex-wrap items-center justify-normal gap-2 rounded-[10px] bg-charcoal-900 px-8 py-6 md:w-[50%] md:flex-wrap md:gap-0 xl:flex-nowrap xl:justify-between xl:gap-4'>
        <div className='xl:w-full'>
          <p className='select-none text-base text-yellow-50 md:text-sm/[12px] md:leading-6'>
            <strong>Get a 10% discount</strong> when you subscribe to our
            monthly newsletter and get free tools to grow your business.
          </p>
        </div>
        <div className='mt-2 flex w-full justify-between gap-6 xl:w-full xs:w-auto'>
          {form && !formResponse && (
            <>
              {/* @ts-ignore */}
              <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
                {(formik) => (
                  <form
                    className='w-full sm:w-auto'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='mx-auto flex w-full flex-col items-center xs:flex-row'>
                      {form &&
                        form?.fieldGroups &&
                        form?.fieldGroups.length > 0 &&
                        form?.fieldGroups.map((fieldGroup, index) => (
                          <div key={index} className='flex w-full items-center'>
                            {fieldGroup.fields &&
                              fieldGroup.fields &&
                              fieldGroup.fields.length > 0 &&
                              fieldGroup.fields.map((field, index) => (
                                <div className='w-full' key={index}>
                                  {field.fieldType.startsWith('email') && (
                                    <div className='flex-shrink-1 gap-1xs:gap-0 flex flex-grow flex-col'>
                                      <NewsletterEmailField
                                        containerProps={{
                                          className: '',
                                        }}
                                        disabled={formik.isSubmitting}
                                        type={
                                          field.hidden
                                            ? 'hidden'
                                            : field.fieldType.startsWith(
                                                  'number'
                                                )
                                              ? 'number'
                                              : field.fieldType.startsWith(
                                                    'email'
                                                  )
                                                ? 'email'
                                                : field.fieldType.startsWith(
                                                      'phone'
                                                    )
                                                  ? 'tel'
                                                  : 'text'
                                        }
                                        labelProps={{
                                          className: 'hidden',
                                        }}
                                        className={`focus:!border-white' -mr-[2px] flex w-full rounded-l-lg rounded-r-lg !border border-[#EFE9DB] !bg-charcoal !p-2 text-white placeholder:text-gray-800 xs:w-full xs:rounded-none xs:rounded-l-lg xs:rounded-r-none`}
                                        name={field.name}
                                        required={field.required}
                                        placeholder={
                                          field.placeholder ?? 'Email'
                                        }
                                        {...(field.fieldType.startsWith(
                                          'number'
                                        ) && {
                                          min: field.validation
                                            ?.minAllowedDigits,
                                          max: field.validation
                                            ?.maxAllowedDigits,
                                        })}
                                        icon={
                                          field.description ? (
                                            <InputToolTip
                                              message={field.description}
                                            />
                                          ) : (
                                            ''
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        ))}

                      <input type='hidden' name='firstname' value={''} />

                      <div className='flex-shrink-0'>
                        <Button
                          className='mx-auto mt-3 flex h-[45px] w-full flex-shrink-0 items-center justify-center rounded-none rounded-l-lg rounded-r-lg bg-[#EFE9DB] px-8 py-1.5 text-center text-sm/[12px] text-black hover:bg-white hover:text-black md:w-full md:min-w-[102px] md:px-8 md:py-3 xl:min-w-fit xs:mx-0 xs:mt-0 xs:w-auto xs:rounded-l-none'
                          type='submit'
                        >
                          <span>
                            {form?.displayOptions?.submitButtonText || 'Submit'}
                          </span>
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          )}

          {formResponse && formResponse.inlineMessage && (
            <div>{formResponse.inlineMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
}
